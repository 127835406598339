body {
    margin: 0;
}

main {
    background-color: white !important;
}

aside {
    background: linear-gradient(180deg,  #009999 30%, #007871 100%) !important;
    /* background: linear-gradient(180deg, #007871 30%, #009999 100%) !important; */
}

#signin_div {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#app_title_img {
    width: 70%;
}

#app_title_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 100%;
    padding: 8px;
}

#header {
    height: 72px;
    border-bottom: solid;
    border-color: #f5f5f5;
    background-color: white;
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
}

#header_div {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #009999;
    /* color: #007871; */
}


#dropdown_space {
    /* Desktop/Body 2 */
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    /* Gray/Gray 80 */
    color: #5E5E5E;
}

#content {
    height: 100vh
}

#prompt_iframe {
    width: 100%;
    height: 100%;
}

#empty_div {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#image_generator_form_div {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#image_output_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} 

#image_output {
    width: 80%;
}

#image_download_button {
    margin-left: 16px
}

.ant-menu-item, .ant-menu-submenu-title {
    color: #FFFFFF !important;
}

.ant-menu-item-selected {
    background: #E7F8F7 !important;
    color: #007871 !important;
}

.ant-menu-title-content{
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    /* font-size: 18px;
    line-height: 130%;   */
    /* color: #FFFFFF; */
}

ul {
    background: #009999 !important;
    /* background: #007871 !important; */
}